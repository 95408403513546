
import Vuex from 'vuex';


const store = new Vuex.Store({
	state: {
		token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
        retoken :localStorage.getItem('retoken') ? localStorage.getItem('retoken') : '',
        resetTime :localStorage.getItem('resetTime') ? localStorage.getItem('resetTime') : '',
	},
	mutations: {
		setToken (state,token) {
			state.token =token;
			localStorage.setItem("token",token.token);
		},
        setReToken(state,retoken)
        {
            state.retoken = retoken;
			localStorage.setItem("retoken",retoken.retoken);
        },
		setResetTime(state,resetTime)
		{
			state.resetTime = resetTime;
			localStorage.setItem("resetTime",resetTime.resetTime);
		},
		delToken (state) {
			state.token = '';
			localStorage.removeItem("token");
			localStorage.removeItem("retoken");
			localStorage.removeItem("resetTime");

		}
	}
})

export default store;
