<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script></script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
:root:root {
  --van-nav-bar-background: #a61f25;
  --van-nav-bar-text-color: #fff;
  --van-nav-bar-icon-color: #fff;
  --van-nav-bar-title-text-color: #fff;
  /* --van-field-input-text-color:#fff; */
  /* --van-gradient-red: linear-gradient(90deg, #072ef9 0%, #142acd 100%); */
  /* --van-gradient-orange: #20b2f1; */
  /* --van-field-label-color:#fff;  */
  /* --van-tabs-default-color: tgba(255, 255, 255, 0);
  --van-tabs-nav-background: tgba(255, 255, 255, 0); */
}
.divButton {
  padding-bottom: 60px;
}
</style>
