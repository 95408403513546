import axios from 'axios'
import router from '../router';

export default {
   //登录-获取token
   Login(data){
    // return  axios.post('api/Public/Login', data, {headers: {'content-type': 'application/x-www-form-urlencoded'}})
    return  axios.post('api/Public/Login', data)
   },
    // //初始化用户登录密码
    // ResetCustomerPwd(data){
    //   return axios.post(API.API.apiUrl.ResetCustomerPwd, data)
    // },
    // //清除用户交易密码
    // DeleteAssetPwd(data){
    //   return axios.post(API.API.apiUrl.DeleteAssetPwd, data)
    // },
    // //清除锁定账号
    // RemoveLockAccount(data){
    //   return axios.post(API.API.apiUrl.RemoveLockAccount, data)
    // },
    //验证码
    GetCaptcha(data){
      return axios.post('api/Public/Captcha',data,{responseType:'blob'})
    },
    toService(){ router.push('/Service');},
}

// 上传图片
export function UpdateImage (data) {
  return axios.post('api/Public/ImageUpdate', data)
}
// 文件下载方法通用
export const fileDownload = (
  url,
  options
) => {
  options.method = options.method || 'GET'
  const reqData = options.data || {}
  return new Promise((resolve, reject) => {
     axios({
      url,
      method: options.method,
      responseType: 'blob', // important
      params: options.method === 'GET' ? reqData : {},
      data: options.method !== 'GET' ? reqData : {}
    })
      .then((response) => {
        const contentDisposition =
          response.headers['content-disposition'] ||
          response.headers['Content-Disposition']
        const resMessage = decodeURIComponent(
          response.headers['x-custom-message'] ||
            response.headers['X-Custom-Message'] ||
            ''
        )

        if (contentDisposition) {
          // 下载文件，优先以filename*=xxx的名字为下载的文件名，如果没有时，再取filename=xxxx的文件名。参考：https://developer.mozilla.org/zh-CN/docs/Web/HTTP/Headers/Content-Disposition
          let filename =
            (contentDisposition.match(/filename\*=[\w\W]*''([\w\W]*)/) ||
              [])[1] ||
            (contentDisposition.match(/filename="?([\s\S]*?)"?$/) || [])[1]

          if (filename) {
            filename = decodeURIComponent(filename)
          }

          // 避免filename解析失败时，下载的文件格式不对的问题
          if (!filename || filename === 'undefined') {
            if (
              response.headers['content-type'] &&
              response.headers['content-type'].indexOf('excel') > 0
            ) {
              filename = '文件.unknown'
            }
          }

          const blobModel = new Blob([response.data])

          if (!(window.navigator).msSaveOrOpenBlob) {
            // 非IE浏览器
            const url = window.URL.createObjectURL(blobModel)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            setTimeout(() => {
              document.body.removeChild(link)
            }, 0)
          } else {
            // IE 11
            (window.navigator).msSaveOrOpenBlob(blobModel, filename)
          }

          resolve(filename)
        } else if (resMessage) {
          // 提示错误信息
          reject(new Error(resMessage))
        } else {
          reject(new Error('服务器错误！请稍后再试或者联系管理员'))
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
