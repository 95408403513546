import { createApp } from 'vue';
import vant from 'vant';
import axios from 'axios';
import store from '@/store/index';
import router from '@/router';
import App from './App.vue';
import VueLuckyCanvas from '@lucky-canvas/vue';
import 'vant/lib/index.css';

// createApp(App).mount('#app')

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vant);
app.use(VueLuckyCanvas);
app.config.globalProperties.$http = axios;
// app.use(promise)
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//     app.component(key, component)
//   }
app.mount('#app');
